.navbarMain{ 
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2; 
    height: auto;   
    transition: 300ms; 
}
.navbarMain--hidden{
    transform: translateY(-70px);
    transition: 2000ms; 
}
.navPc{
    display: block;
}
.navMb{
    display: none;
}

@media(max-width:990px){
    .navPc{
        display: none;
    }
    .navMb{
        display: block;
    }
}