.box{
    background: whitesmoke; 
   
}
.box:hover{
    box-shadow: 0 0 50px #ccc;
}
.option{
    cursor: pointer;
}
.option:hover{
     color: rgb(82, 132, 240);
}